import { UserService } from "../services/user/user.service";

export interface PrepMsgInputs {
  orgID: string;
  indID?: string;
  orgMgrID?: string;
  leagueID?: string;
  visitID?: string;
  passTypeID?: string;
  passID?: string;
  designTypeID?: string;
  variationID?: string;
  indPhotoIps?: {
    mode: "capture" | "display";
    photoPrefValue?: string;
    isPhotoMustBeApproved?: boolean;
    photoUrl?: string;
  };
  currentCycle?: string;
  incidentIps?: {
    orgID: string;
    indID: string;
    _BIID: string;
  };
}

export interface PrepMsgPreCookedData {
  orgData?: any;
  indData?: any;
  orgMgrData?: any;
  indPhotoData?: any;
  indPhotoUrl?: string;
  visitData?: any;
  studentCrossingTypes?: any;
  visitorCrossingTypes?: any;
  certConfigs?: any;
  indCerts?: any;
  passTypeData?: any;
  passData?: any;
  variationData?: any;
  indGuardiansData?: any;
  orgDisplayCycle?: any;
  divisionsList?: any;
  _BIData?: any;
  incidentData?: any;
  orgEmail?: any;
  orgLogoUrl?: string;
}

export interface PrepMsgOptions {
  orgProps?: string[];
  indProps?: string[];
  orgMgrProps?: string[];
  grdnProps?: string[];
  customCtx?: {};
  customFields?: any[];
  additionalRqrmnts?: {};
}

// ===== START
// Ind list component related types
// =====

// --- Ind list item related types START
export interface IndItemUIControls {
  detailsBtn?: boolean;
  deleteBtn?: boolean;
  actionBtn?: boolean;
  renderBtn?: boolean;
  printBtn?: boolean;
  selectCheckbox?: boolean;
}

export interface IndListCounters {
  totalInds?: number;
  selectedInds?: number;
}

// --- details event data interface
interface IndItemDetailsEventData {
  indData: any;
}

// --- actions event data interface
interface IndItemActionsEventData {
  indData: any;
  sourceEvent: any;
}

// --- delete event data interface
interface IndItemDeleteEventData {
  indData: any;
  indHash: string;
}

// --- render event data interface
interface IndItemRenderEventData {
  indData: any;
}

// --- print event data interface
interface IndItemPrintEventData {
  indData: any;
}

// --- click event data interface
interface IndItemClickEventData {
  indData: any;
}

// --- generic event type
export interface IndItemEvent<E extends IndItemEventName, T> {
  event: E;
  data?: T;
}

// --- specific event (eg. delete, actions, render, print) types
export type IndItemDetailsEvent = IndItemEvent<
  "details",
  IndItemDetailsEventData
>;
export type IndItemActionsEvent = IndItemEvent<
  "actions",
  IndItemActionsEventData
>;
export type IndItemDeleteEvent = IndItemEvent<"delete", IndItemDeleteEventData>;
export type IndItemRenderEvent = IndItemEvent<"render", IndItemRenderEventData>;
export type IndItemPrintEvent = IndItemEvent<"print", IndItemPrintEventData>;
export type IndItemClickEvent = IndItemEvent<"click", IndItemClickEventData>;

// --- event names type
export type IndItemEventName =
  | "details"
  | "actions"
  | "delete"
  | "render"
  | "print"
  | "click";

// --- event types
export type IndItemEventTypes =
  | IndItemDetailsEvent
  | IndItemActionsEvent
  | IndItemDeleteEvent
  | IndItemRenderEvent
  | IndItemPrintEvent
  | IndItemClickEvent;

// --- Ind list item related types END

// --- Ind list related types START

// --- ind list events
export type IndListEvent<E extends IndListEventName, T> = {
  event: E;
  data: T;
};

// --- specific event (eg. select/deselect All, invert selection) types
export type IndListSelectAllEvent = IndListEvent<"selectAll", undefined>;
export type IndListDeselectAllEvent = IndListEvent<"deselectAll", undefined>;
export type IndListInvertSelectionEvent = IndListEvent<
  "invertSelection",
  undefined
>;

// --- event names type
export type IndListEventName = "selectAll" | "invertSelection" | "deselectAll";

// --- event types
export type IndListEventTypes =
  | IndListSelectAllEvent
  | IndListDeselectAllEvent
  | IndListInvertSelectionEvent;

// --- Ind list related types END

// =====
// Ind list component related types
// ===== END

// ===== START
// Ind list filter component related types
// =====

export interface IndListFilterDefaults {
  searchText?: string;
}

// --- render event data interface
interface IndListFilterFilteredListChangedEventData {
  filteredList: any[];
}

// --- ind list filter events
export type IndListFilterEvent<E extends IndListFilterEventName, T> = {
  event: E;
  data: T;
};

// --- specific event (eg. filteredListChanged) types
export type IndListFilterFilteredListChangeEvent = IndListFilterEvent<
  "filteredListChanged",
  IndListFilterFilteredListChangedEventData
>;

// --- event names type
export type IndListFilterEventName = "filteredListChanged";

// --- event types
export type IndListFilterEventTypes = IndListFilterFilteredListChangeEvent;
// =====
// Ind list filter component related types
// =====

export interface AlertButton<T> {
  btnText: string;
  value: T;
}

export interface AuthDependency {
  forRule: "static" | "dynamic"; // for which kind of rule, this dependency is for
  requires: string; // what is the required data (dependency) (eg. relations)
}

export enum IndSearchType {
  NAME = "name",
  STUDENT_ID = "studentID",
  PHONE = "mobilePhone",
  IND_ID = "indId",
  CUSTOM3 = "custom3",
  ROLE = "role",
  HASH = "hash",
  LAST_NAME = "lastName",
  DIVISION = "division",
  BIRTH = "birth",
  JERSEY_NUMBER = "jerseyNumber"
}