import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { routes } from './app.routes';

import { CalendarDateFormatter, CalendarModule, CalendarNativeDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment.stage';
import { initializeApp } from 'firebase/app';
import { enableProdMode } from '@angular/core';
import {provideHttpClient} from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Utils } from './shared/utils/utils';
import { GetIndCountPipe } from './shared/pipes/getIndCount.pipe';
import { DayViewSchedulerCalendarUtils } from './shared/components/schedule-configuration-calendar/schedule-configuration-calendar.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import * as Sentry from "@sentry/angular";
if (environment.production) {
  enableProdMode();
}
initializeApp(environment.firebaseConfig);
export class DemoModule extends CalendarNativeDateFormatter {
  public override weekViewHour({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat('pt-BR', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true 
    }).format(date);
  }
}

Sentry.init({
  dsn: "https://4a7f0e08aa75ed6831e6ec42c6c8bf97@o4507855679127552.ingest.us.sentry.io/4507882008150016",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://portal.high5.id/"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: environment.production ? "prod" : "stage",
});


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: DemoModule,
        },
      }),
      FlatpickrModule.forRoot(),
      BrowserAnimationsModule,
      AngularFireAuthModule,
      AngularFireModule,
      AngularFireDatabaseModule
    ),
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    provideHttpClient(),
    MessageService,
    ConfirmationService,
    GetIndCountPipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Utils,
    DayViewSchedulerCalendarUtils, provideAnimationsAsync(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
