import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { StudioComponent } from './studio/studio.component';
import { GenericComponent } from './generic/generic.component';
import { SlotBookingComponent } from './generic/slot-booking/slot-booking.component';
import { PickIndividualComponent } from './generic/pick-individual/pick-individual.component';
import { FindOrganizationComponent } from './generic/find-organization/find-organization.component';
import { GeneralDropboxComponent } from './generic/general-dropbox/general-dropbox.component';
import { SlotBookingListComponent } from './generic/slot-booking-list/slot-booking-list.component';

export const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./login/login-routing.module').then((m) => m.LoginRoutingModule),
  },
  {
    path: 'generic',
    component: GenericComponent,
    children: [
      {
        path: 'slot-scheduling',
        component: SlotBookingComponent,
      },
      {
        path: 'slot-booking',
        component: PickIndividualComponent,
      },
      {
        path: 'organization',
        component: FindOrganizationComponent,
      },
      {
        path: 'general-dropbox',
        title: 'General Inbox',
        component: GeneralDropboxComponent
      },
      {
        path: 'booking-list',
        title: 'booking-list',
        component: SlotBookingListComponent
      },
      {
        path: '**',
        redirectTo: 'organization',
        pathMatch: 'full',
      },
    ]
  },
  {
    path: '',
    component: StudioComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        title: 'Dashboard',
        loadChildren: () =>
          import('./studio/dashboard/dashboard-routing.module').then(
            (m) => m.DashboardRoutingModule
          ),
      },
      {
        path: 'districts',
        title: 'Districts',
        canDeactivate: [AuthGuard],
        loadChildren: () =>
          import('./studio/district/district-routing.module').then(
            (m) => m.DistrictRoutingModule
          ),
      },
      {
        path: 'schools',
        title: 'Schools',
        loadChildren: () =>
          import('./studio/school/school-routing.module').then(
            (m) => m.SchoolRoutingModule
          ),
      },
      {
        path: 'events',
        title: 'Events',
        loadChildren: () =>
          import('./studio/event/event-routing.module').then(
            (m) => m.EventRoutingModule
          ),
      },
      {
        path: 'orders',
        title: 'Orders',
        canDeactivate: [AuthGuard],
        loadChildren: () =>
          import('./studio/order/order-routing.module').then(
            (m) => m.OrderRoutingModule
          ),
      },
      {
        path: 'communication',
        title: 'Communications',
        canDeactivate: [AuthGuard],
        loadChildren: () =>
          import('./studio/communication/communication-routing.module').then(
            (m) => m.CommunicationRoutingModule
          ),
      },
      {
        path: 'albums',
        title: 'Albums',
        canDeactivate: [AuthGuard],
        loadChildren: () =>
          import('./studio/albums/albums-routing.module').then(
            (m) => m.AlbumsRoutingModule
          ),
      },
      {
        path: 'general-data-drops',
        title: 'General Inbox',
        canDeactivate: [AuthGuard],
        loadChildren: () =>
          import('./studio/general-data-drops/general-data-drops-routing.module').then(
            (m) => m.GeneralDataDropsRoutingModule
          ),
      },
      {
        path: 'settings',
        title: 'Settings',
        canDeactivate: [AuthGuard],
        loadChildren: () =>
          import('./studio/setting/setting-routing.module').then(
            (m) => m.SettingRoutingModule
          ),
      },
      {
        path: '**',
        redirectTo: '/auth',
        pathMatch: 'full',
      },
    ],
  },
];
