<div class="container d-flex justify-content-center align-items-center" style="min-height: 100vh">
    <div class="card text-center booking-info d-flex flex-col gap-3">
        <div class="d-flex align-items-center gap-3">
            <div class="heading">
                <span>Your school:</span>
            </div>
            <div class="detail">
                <span *ngIf="!isLoading">{{ organization || "-" }}</span>
                <p-skeleton *ngIf="isLoading" width="200px" height="20px" />
            </div>
        </div>
        <!-- <div class="d-flex gap-3">
            <div class="heading">
                <span>Your Event:</span>
            </div>
            <div class="detail">
                <span *ngIf="!isLoading">{{ eventMetaData?.displayName || "-" }}</span>
                <p-skeleton *ngIf="isLoading" width="200px" height="20px" styleClass="mb-2" />
            </div>
        </div> -->
        <div class="d-flex align-items-center flex-sm-col gap-3">
            <div class="heading">
                <span>Your Event:</span>
            </div>
            <div class="detail">
                @if (isShowEventDropDown) {
                    <p-dropdown class="cmn-dropdown w-full event-selection-dropdown" placeholder="Select Event"
                        [(ngModel)]="selectedEventId" [options]="eventList" optionLabel="meta.displayName"
                        optionValue="key"></p-dropdown>
                } @else {
                <span>{{ (this.eventMetaData?.displayName) || '-' }}</span>
                }
            </div>
        </div>

        @if (selectedEventId) {
        <form [formGroup]="genericPageForm" class="mt-3">
            <div class="d-flex flex-col gap-2 max-w-250px mx-auto">
                <div class="w-full">
                    <span class="cus-float-label">
                        <input id="studentId" type="text" class="w-full" placeholder="Enter your Student ID" pInputText
                            formControlName="studentId" />
                    </span>
                    <div class="invalid-feedback text-start"
                        *ngIf="genericPageForm.get('studentId').invalid && (genericPageForm.get('studentId').dirty || genericPageForm.get('studentId').touched)">
                        Please enter student ID
                    </div>
                </div>
                <p-button class="cmn-btn-primary mt-1" (click)="verifyStudentNumber()">
                    Next
                </p-button>
            </div>
        </form>
        }
    </div>
</div>

<p-toast></p-toast>
<p-confirmDialog class="cus-confirmDialog"></p-confirmDialog>