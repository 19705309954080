import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { UserService } from '../shared/services/user/user.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import lodash from 'lodash';
import { Observable, takeUntil, filter, skip } from 'rxjs';
import { LocalStorageKeys, SlotStatus } from '../shared/constants/enums';
import { AuthService } from '../shared/services/auth/auth.service';
import { CommonServiceService } from '../shared/services/common-service.service';
import { EventService } from '../shared/services/event/event.service';
import { IndividualService } from '../shared/services/individuals/individual.service';
import { LocalStorageService } from '../shared/services/storage/local-storage.service';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-generic',
  standalone: true,
  imports: [
    RouterModule,
    SharedModule
  ],
  templateUrl: './generic.component.html',
  styleUrl: './generic.component.scss'
})
export class GenericComponent {
  authStateReadyObservable: Observable<any>;

  constructor(
    public router: ActivatedRoute,
    public userService: UserService,
    public eventService: EventService,
    public individualService: IndividualService,
    private commonFun: CommonServiceService,
    private authService: AuthService,
    private db: AngularFireDatabase,
    private localStorageService: LocalStorageService
  ) {
    // --- prepare auth state ready observable
    this.authStateReadyObservable = new Observable((observer) => {
      this.eventService.subscribe('user:authStateReady', (...args) =>
        observer.next(...args)
      );
    });
    // --- setup auth state ready listener
    this.setupAuthStateReadyListener();

    // --- prepare auth state
    this.prepareAuthState().catch(async (e) => {
      if (e && e.message && e.message.toLowerCase().includes('loading chunk'))
        throw e; // if its chunk loading failed error, throw it so that it can be handled by global error handler
      this.commonFun.appendLog(
        'Error preparing auth: ' + this.commonFun.prepareErrorMessage(e)
      );
      this.commonFun.reportLogs();
    });
  }

  // --- setup auth state ready listener
  setupAuthStateReadyListener() {
    this.authStateReadyObservable.subscribe(this.onAuthStateReady.bind(this));
  }

    // --- triggers when auth state gets ready
    onAuthStateReady(authState: firebase.User) {
      if (!authState || !authState.uid) {
        console.error(
          'user:authStateReady event missing valid authState!',
          authState
        );
        return;
      }
  
      // --- listen to claims metadata changes for current user
      const onClaimsMetadataChange = async (updatedValue: any) => {
        // --- refresh claims
        await this.authService.refreshIDToken();
        this.eventService.publish('user:authTokenRefreshed');
      };
      this.db
        .object(`metadata/${authState.uid}/claimsUpdatedAt`)
        .valueChanges()
        .pipe(takeUntil(this.authStateReadyObservable))
        .pipe(
          filter((value: any) => {
            //  --- if timestamp is changed, then only proceed
            let isClaimsUpdateAtChanged =
              value &&
              this.localStorageService.getItem(
                LocalStorageKeys.claimsUpdatedAt
              ) != value;
  
            // --- store claims update timestamp in localstorage
            if (isClaimsUpdateAtChanged)
              this.localStorageService.setItem(
                LocalStorageKeys.claimsUpdatedAt,
                value
              );
  
            return isClaimsUpdateAtChanged;
          })
        )
        .subscribe(onClaimsMetadataChange);
  
      // --- listen to password metadata changes for current user
      const onPwMetadataChange = async (updatedValue: any) => {
        let currentPath = location.pathname;
        // TVT location path name
        if (
          !lodash.some(
            CommonServiceService.tvtPathArr,
            (route) => route == currentPath || `/stage${route}` == currentPath
          )
        ) {
          window.location.reload();
        }
      };
      this.db
        .object(`metadata/${authState.uid}/pwUpdatedAt`)
        .valueChanges()
        .pipe(takeUntil(this.authStateReadyObservable))
        .pipe(skip(1))
        .subscribe(onPwMetadataChange);
    }

  // --- prepare auth state (eg. sign in anonymous user if required)
  async prepareAuthState() {
    // --- if no user is signed, sign in as anonymous user
    this.commonFun.appendLog('prepareAuthState begin');
    let authState = await this.authService.getAuthState();
    this.commonFun.appendLog(`auth present?: ${!!authState}`);

    if (!authState) {
      this.commonFun.appendLog(`anonymous sign in begin`);
      let user = await this.authService.signInAnonymously();
      this.commonFun.appendLog(
        `anonymous sign in done: ${lodash.get(user, 'user.uid')}`
      );
      authState = await this.authService.getAuthState();
      this.commonFun.appendLog(`auth present?: ${!!authState}`);
    }

    this.eventService.publish('user:authStateReady', authState);
    this.authService.isAuthStateReady = true;
    this.commonFun.appendLog(`published auth state ready event`);
  }
}
