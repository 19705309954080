<div class="high5-theme main-div">
  <div class="second-div">
    <div class="logo">
      <img class="img-fluid" src="../../../assets/png/Logo.png" alt="log image">
    </div>
    <p class="mt-0 mb-3 fs-16 text-white"> Please enter a few letters of your organization name </p>
    <!-- field input -->

    <span class="cus-float-label">
      <input class="w-full max-w-400px org-search-input" type="text" pInputText [(ngModel)]="orgName"
        (ngModelChange)="onOrgSrchInputChange()">
    </span>

    <!-- search results -->
    <div class="scroll-div" *ngIf="showOrgList.length > 0">
      <ng-container *ngFor="let org of showOrgList" class="org-name-container">
        <p class="org-name-p" (click)="selectOrg(org)">{{org.orgName}}<br />{{org.shippingCity ? '[' + org.shippingCity
          : ''}}{{org.shippingState ? org.shippingCity ? ', ' + org.shippingState + ']' : '[' + org.shippingState + ']'
          : org.shippingCity ? ']' : ''}} </p>
        <hr style="margin: 0px;" class="grey" />
      </ng-container>
    </div>
  </div>
</div>

<p-toast></p-toast>
<p-confirmDialog class="cus-confirmDialog"></p-confirmDialog>