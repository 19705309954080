<div class="main_block">
    <div class="log_block">
        <img src="../../../assets/png/Logo.png" alt="log image" />
    </div>
    <div class="manu_list overflow-auto">
        <ul>
            <li routerLinkActive="active" [routerLink]="['/dashboard']" (click)="page = 'Dashboard'">
                <a>


                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="124.105px" height="124.105px"
                        viewBox="0 0 124.105 124.105" xml:space="preserve">
                        <g>
                            <path
                                d="M113.296,27.403l-8.699,12.3c1.399,2.6,2.5,5.3,3.399,8.1l-2,0.6c-2.8,0.9-4.399,3.7-3.899,6.6v0.1c0.5,2.9,3,5,5.899,4.9
                           l2.101-0.1c0.1,2.599,0,5.3-0.301,8c-0.6,4.4-1.699,8.7-3.399,12.6l-1.9-0.799c-2.7-1.101-5.7-0.101-7.2,2.399
                           c-0.1,0.101-0.1,0.2-0.199,0.3c-1.5,2.5-0.801,5.7,1.5,7.4l1.699,1.3c-4.3,5.601-9.8,10.2-16.1,13.399l-1-1.899
                           c-1.3-2.601-4.4-3.7-7.2-2.7h-0.1c-2.8,1-4.4,3.801-3.8,6.7l0.5,2.101c-5.301,1.199-10.8,1.5-16.4,0.799
                           c-1.5-0.199-3-0.5-4.5-0.799l0.4-1.9c0.6-2.801-0.9-5.6-3.5-6.6c-0.1,0-0.2-0.101-0.3-0.101c-2.7-1.101-5.9,0-7.3,2.601l-1,1.799
                           c-6.3-3.299-11.7-7.899-15.9-13.399l1.7-1.3c2.3-1.801,2.9-5,1.5-7.4c-0.1-0.1-0.1-0.199-0.2-0.3c-1.4-2.5-4.5-3.5-7.2-2.399
                           l-1.9,0.799c-2.7-6.399-4-13.399-3.7-20.599l2.3,0.1c2.9,0.1,5.5-1.9,5.9-4.8v-0.1c0.5-2.8-1.3-5.6-4-6.4l-2.2-0.6
                           c2.1-7,5.8-13.2,10.5-18.4l1.5,1.4c2.1,2,5.4,2,7.6,0.2c0.1-0.1,0.2-0.2,0.3-0.2c2.2-1.9,2.7-5.1,1.1-7.5l-1.1-1.8
                           c5.8-3.8,12.5-6.3,19.6-7.2l0.3,2.2c0.4,2.9,2.8,5,5.7,5c0.2,0,0.5,0,0.7,0c2.9,0,5.299-2.1,5.7-5l0.3-2.3c2.9,0.4,5.8,1.1,8.5,1.9
                           l10.5-10.4c0.101-0.1,0.2-0.2,0.3-0.3l0.101-0.1c-5.601-2.6-11.7-4.3-18.101-5.1c-34-4.3-65,19.8-69.299,53.8s19.8,65,53.8,69.3
                           c34,4.3,65-19.8,69.3-53.8c2-15.6-2-30.5-10.2-42.5C113.396,27.203,113.396,27.303,113.296,27.403z"
                                fill="white" />
                            <path d="M59.597,50.203c-6.5,1.4-10.8,8-9,14.9c1.3,5.101,6,8.8,11.3,8.899c0.1,0,0.2,0,0.3,0c6.799,0,11.9-5.399,12-11.799
                           l22-31.201l7.4-10.5c0.899-1.7,0.699-3.5-0.101-4.8c-0.6-0.9-1.399-1.6-2.399-2c-0.601-0.2-1.2-0.4-1.801-0.4
                           c-1.1,0-2.199,0.4-3.199,1.3l-8.601,8.4L59.597,50.203z" fill="white" />
                        </g>
                    </svg>
                    Dashboard</a>
            </li>
            <li routerLinkActive="active" [routerLink]="['/districts']" (click)="page = 'Districts'">
                <a>
                    <svg width="415" height="315" viewBox="0 0 415 315" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M398.1 199.7H365.3V157.3C365.3 152.5 362.1 149.3 357.3 149.3H215.7V114.9H248.5C258.1 114.9 265.3 107.7 265.3 98.1V17.3C265.3 7.7 258.1 0.5 248.5 0.5H166.9C157.3 0.5 150.1 7.7 150.1 17.3V98.9C150.1 108.5 157.3 115.7 166.9 115.7H199.7V149.3H58.1C53.3 149.3 50.1 152.5 50.1 157.3V199.7H17.3C7.7 199.7 0.5 206.9 0.5 216.5V298.1C0.5 307.7 7.7 314.9 17.3 314.9H98.9C108.5 314.9 115.7 307.7 115.7 298.1V216.5C115.7 206.9 108.5 199.7 98.9 199.7H66.1V165.3H199.7V199.7H166.9C157.3 199.7 150.1 206.9 150.1 216.5V298.1C150.1 307.7 157.3 314.9 166.9 314.9H248.5C258.1 314.9 265.3 307.7 265.3 298.1V216.5C265.3 206.9 258.1 199.7 248.5 199.7H215.7V165.3H349.3V199.7H316.5C306.9 199.7 299.7 206.9 299.7 216.5V298.1C299.7 307.7 306.9 314.9 316.5 314.9H398.1C407.7 314.9 414.9 307.7 414.9 298.1V216.5C414.9 206.9 406.9 199.7 398.1 199.7ZM99.7 216.5C99.7 215.7 98.9 215.7 99.7 216.5V298.1C99.7 298.9 99.7 298.9 98.9 298.9H17.3C16.5 298.9 16.5 298.9 16.5 298.1V216.5C16.5 215.7 16.5 215.7 17.3 215.7L99.7 216.5ZM249.3 216.5C249.3 215.7 248.5 215.7 249.3 216.5V298.1C249.3 298.9 249.3 298.9 248.5 298.9H166.9C166.1 298.9 166.1 298.9 166.1 298.1V216.5C166.1 215.7 166.1 215.7 166.9 215.7L249.3 216.5ZM166.1 98.1C166.9 98.9 166.1 98.9 166.1 98.1V17.3C166.1 16.5 166.1 16.5 166.9 16.5H248.5C249.3 16.5 249.3 16.5 249.3 17.3V98.9C249.3 99.7 249.3 99.7 248.5 99.7L166.1 98.1ZM398.9 297.3C398.9 298.1 398.1 298.1 398.9 297.3L316.5 298.1C315.7 298.1 315.7 298.1 315.7 297.3V216.5C315.7 215.7 315.7 215.7 316.5 215.7H398.1C398.9 215.7 398.9 215.7 398.9 216.5V297.3Z"
                            fill="white" />
                    </svg>
                    Districts</a>
            </li>
            <li routerLinkActive="active" [routerLink]="['/schools']" (click)="page = 'Schools'">
                <a>
                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M42.5684 42.3633V23.8184H32.8977L23.8184 12.9974V7.32419H31.4323V0H21.1816V12.9974L12.1024 23.8184H2.43167V42.3633H0V45H45V42.3633H42.5684ZM7.06061 28.125H9.69732V31.875H7.06061V28.125ZM9.76491 39.375H7.12819V35.625H9.76491V39.375ZM22.5 18.3691C24.7778 18.3691 26.6309 20.2222 26.6309 22.5C26.6309 24.7778 24.7778 26.6309 22.5 26.6309C20.2222 26.6309 18.3691 24.7778 18.3691 22.5C18.3691 20.2222 20.2222 18.3691 22.5 18.3691ZM29.4434 42.3633H26.8066V33.1934H23.8184V42.3633H21.1816V33.1934H18.1934V42.3633H15.5566V30.5567H29.4434V42.3633ZM37.8719 39.375H35.2352V35.625H37.8719V39.375ZM37.9394 31.875H35.3027V28.125H37.9394V31.875Z"
                            fill="white" />
                    </svg>
                    Schools</a>
            </li>
            <li routerLinkActive="active" [routerLink]="['/events']" (click)="page = 'Events'">
                <a>
                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_0_45)">
                            <path
                                d="M17.3358 28.9711L16.6454 33.0719C16.5513 33.6326 16.7813 34.1975 17.2403 34.5321C17.6999 34.866 18.3103 34.9107 18.8126 34.6475L22.4994 32.7229L26.1863 34.6478C26.4044 34.761 26.6435 34.818 26.88 34.818C27.1897 34.818 27.4987 34.721 27.7594 34.5325C28.2187 34.1979 28.4494 33.6329 28.3546 33.0723L27.6649 28.9715L30.6336 26.0594C31.0395 25.6618 31.1841 25.0683 31.01 24.5282C30.8351 23.9885 30.3671 23.5933 29.8054 23.5104L25.6917 22.9004L23.8388 19.1764C23.5876 18.668 23.0669 18.346 22.4991 18.346C21.9323 18.346 21.4121 18.668 21.1586 19.1764L19.3076 22.9004L15.1938 23.5101C14.631 23.593 14.1652 23.9881 13.99 24.5279C13.8148 25.0679 13.959 25.6621 14.3657 26.0591L17.3358 28.9711Z"
                                fill="white" />
                            <path
                                d="M40.1796 4.784H35.4691V2.17117C35.4691 0.972014 34.4971 0 33.2976 0H32.9533C31.7541 0 30.7825 0.972014 30.7825 2.17117V4.784H14.1627V2.17117C14.1627 0.972014 13.1907 0 11.9919 0H11.6472C10.4481 0 9.4764 0.972014 9.4764 2.17117V4.784H4.82076C2.35719 4.784 0.352234 6.78823 0.352234 9.25252V40.5318C0.352234 42.9947 2.35719 45.0004 4.82076 45.0004H40.1799C42.6431 45.0004 44.6485 42.995 44.6485 40.5318V9.25252C44.6481 6.78823 42.6428 4.784 40.1796 4.784ZM39.5966 39.9488H5.40375V15.5998H39.5966V39.9488Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_0_45">
                                <rect width="45" height="45" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    Events</a>
            </li>
            <li routerLinkActive="active" [routerLink]="['/albums']" (click)="page = 'Albums'">
                <a>
                    <svg width="683" height="683" viewBox="0 0 683 683" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M62.8459 619.82C51.4833 619.82 40.5846 615.307 32.55 607.271C24.5153 599.236 20.002 588.339 20.002 576.976V105.69C20.002 82.027 39.1833 62.8457 62.8459 62.8457H173.633C184.995 62.8457 195.893 67.359 203.929 75.3937C215.238 86.7057 231.786 103.251 243.095 114.563C251.131 122.598 262.029 127.111 273.391 127.111H619.822C631.185 127.111 642.082 131.626 650.117 139.66C658.151 147.695 662.666 158.592 662.666 169.956V576.976C662.666 588.339 658.151 599.236 650.117 607.271C642.082 615.307 631.185 619.82 619.822 619.82H62.8459Z"
                            stroke="white" stroke-width="40" stroke-miterlimit="0" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M662.666 277.067C662.666 265.704 658.151 254.806 650.117 246.771C642.082 238.736 631.186 234.223 619.822 234.223H62.8459C51.4833 234.223 40.5846 238.736 32.5513 246.771C24.5153 254.806 20.002 265.704 20.002 277.067V576.976C20.002 588.34 24.5153 599.238 32.5513 607.272C40.5846 615.307 51.4833 619.82 62.8459 619.82H619.822C631.186 619.82 642.082 615.307 650.117 607.272C658.151 599.238 662.666 588.34 662.666 576.976V277.067Z"
                            stroke="white" stroke-width="40" stroke-miterlimit="0" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                    Albums</a>
            </li>


            <li routerLinkActive="active" [routerLink]="['/general-data-drops']" (click)="page = 'General Inbox'">
              <a>
                  <svg width="683" height="683" viewBox="0 0 683 683" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M62.8459 619.82C51.4833 619.82 40.5846 615.307 32.55 607.271C24.5153 599.236 20.002 588.339 20.002 576.976V105.69C20.002 82.027 39.1833 62.8457 62.8459 62.8457H173.633C184.995 62.8457 195.893 67.359 203.929 75.3937C215.238 86.7057 231.786 103.251 243.095 114.563C251.131 122.598 262.029 127.111 273.391 127.111H619.822C631.185 127.111 642.082 131.626 650.117 139.66C658.151 147.695 662.666 158.592 662.666 169.956V576.976C662.666 588.339 658.151 599.236 650.117 607.271C642.082 615.307 631.185 619.82 619.822 619.82H62.8459Z"
                          stroke="white" stroke-width="40" stroke-miterlimit="0" stroke-linecap="round"
                          stroke-linejoin="round" />
                      <path
                          d="M662.666 277.067C662.666 265.704 658.151 254.806 650.117 246.771C642.082 238.736 631.186 234.223 619.822 234.223H62.8459C51.4833 234.223 40.5846 238.736 32.5513 246.771C24.5153 254.806 20.002 265.704 20.002 277.067V576.976C20.002 588.34 24.5153 599.238 32.5513 607.272C40.5846 615.307 51.4833 619.82 62.8459 619.82H619.822C631.186 619.82 642.082 615.307 650.117 607.272C658.151 599.238 662.666 588.34 662.666 576.976V277.067Z"
                          stroke="white" stroke-width="40" stroke-miterlimit="0" stroke-linecap="round"
                          stroke-linejoin="round" />
                  </svg>
                  General Inbox</a>
          </li>

            <!-- <li routerLinkActive="active" [routerLink]="['/orders']" (click)="page = 'Orders'">
                <a>
                    <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_292_1673)">
                            <path
                                d="M407.275 405.332H215.403C187.733 405.332 163.2 387.177 155.712 361.172L90.8587 141.417C88.5547 133.46 80.896 127.999 72.1493 127.999H21.3333C9.536 127.999 0 118.441 0 106.665C0 94.8894 9.536 85.332 21.3333 85.332H72.1493C99.8187 85.332 124.331 103.487 131.819 129.492L143.957 170.665H470.251C483.563 170.665 496.192 177.001 504.021 187.625C511.787 198.164 514.027 211.348 510.165 223.807L466.155 363.497C457.536 388.713 433.92 405.332 407.275 405.332Z"
                                fill="white" />
                            <path
                                d="M234.667 512.001C211.136 512.001 192 492.865 192 469.335C192 445.804 211.136 426.668 234.667 426.668C258.197 426.668 277.333 445.804 277.333 469.335C277.333 492.865 258.197 512.001 234.667 512.001Z"
                                fill="white" />
                            <path
                                d="M384.001 512.001C360.47 512.001 341.334 492.865 341.334 469.335C341.334 445.804 360.47 426.668 384.001 426.668C407.531 426.668 426.667 445.804 426.667 469.335C426.667 492.865 407.531 512.001 384.001 512.001Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_292_1673">
                                <rect width="512" height="512" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    Orders</a>
            </li> -->
            <!-- <li routerLinkActive="active" [routerLink]="['/communications']" (click)="page = 'Communications'">
                <a>
                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_0_62)">
                            <path
                                d="M36.2696 42.6218C36.6554 42.6218 37.0361 42.7686 37.3257 43.0483C39.1771 44.836 41.8776 45.4313 44.2783 44.6862C42.7333 41.4675 42.5192 37.78 43.7165 34.3656C45.2024 30.1176 45.5748 25.8597 43.8837 21.5894C42.398 17.838 39.531 14.7214 35.9026 12.9441C36.1259 14.1341 36.2384 15.3467 36.2384 16.572C36.2385 21.7732 34.2384 26.6651 30.607 30.3466C26.9752 34.0286 22.1067 36.0989 16.8986 36.1763C15.931 36.1915 14.9677 36.1346 14.0154 36.009C16.8777 41.0262 22.2759 44.2938 28.2079 44.3818C30.8028 44.4219 33.2944 43.8782 35.6148 42.7702C35.8238 42.6702 36.0476 42.6218 36.2696 42.6218Z"
                                fill="white" />
                            <path
                                d="M16.9415 0.00333692C7.70245 -0.180116 0.0222663 7.29412 -1.09378e-05 16.5311C-0.00546886 18.7879 0.435063 20.976 1.30944 23.0349C1.32214 23.0648 1.33395 23.0951 1.34464 23.1259C2.54193 26.5402 2.32796 30.2276 0.782811 33.4465C3.18374 34.1912 5.88396 33.5963 7.73542 31.8084C8.19155 31.3678 8.87401 31.2568 9.44631 31.5304C11.7664 32.6385 14.2583 33.1838 16.8532 33.1422C26.0199 33.006 33.2004 25.7275 33.2003 16.5719C33.2004 7.61613 25.9066 0.18356 16.9415 0.00333692ZM7.74545 19.0469C6.41806 19.0469 5.34196 17.9726 5.34196 16.6474C5.34196 15.3223 6.41806 14.248 7.74545 14.248C9.07284 14.248 10.1489 15.3223 10.1489 16.6474C10.1488 17.9726 9.07284 19.0469 7.74545 19.0469ZM16.6002 19.0469C15.2728 19.0469 14.1967 17.9726 14.1967 16.6474C14.1967 15.3223 15.2728 14.248 16.6002 14.248C17.9276 14.248 19.0037 15.3223 19.0037 16.6474C19.0037 17.9726 17.9276 19.0469 16.6002 19.0469ZM25.4551 19.0469C24.1277 19.0469 23.0516 17.9726 23.0516 16.6474C23.0516 15.3223 24.1277 14.248 25.4551 14.248C26.7824 14.248 27.8586 15.3223 27.8586 16.6474C27.8586 17.9726 26.7824 19.0469 25.4551 19.0469Z"
                                fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_0_62">
                                <rect width="45" height="45" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    Communications</a>
            </li> -->
        </ul>
    </div>
    <div class="manu_list mt-auto">
        <ul>
            <li>
                <a (onClick)="goToAdminPanel()" (click)="goToAdminPanel()">
                    <img src="../../assets/png/High5_logo.png" alt="log image" class="high5_logo" />
                    High5 Admin Panel</a>
            </li>
            <li routerLinkActive="active" [routerLink]="['/settings']" (click)="page = 'Settings'; onPageChange()">
                <a>
                    <svg xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512">
                        <path fill="#fff"
                            d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                    </svg>
                    Settings</a>
            </li>
            <li>
                <a (click)="logout()">
                    <img src="../../../assets/png/Profile.png" alt="log image" />
                    Logout</a>
            </li>
        </ul>
    </div>
</div>

<div class="header_block">
    <div class="title-back" (click)="isShowBack ? back() : ''">
        <a class="icon h-full d-flex align-items-center" *ngIf="isShowBack">
            <i class="pi pi-arrow-left"></i>
        </a>
        <h4 class="ml-1"> {{ activePage ? (activePage | titlecase) : '' }} </h4>
    </div>
    <ng-container *ngIf="(currentEventData || currentSchoolData) && (currentPage === 'schedule-configuration' || currentPage === 'bookings' || currentPage === 'subject-selection' || currentPage === 'messaging' || currentPage === 'database' || currentPage === 'edit')">
      <div class="eventTitle" *ngIf="currentEventData">
        <p>{{ currentEventData?.event?.meta?.displayName | titlecase }}</p>
      </div>
      <div class="schoolTitle" *ngIf="currentSchoolData">
        <p>{{ currentSchoolData?.orgName | titlecase }}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading; else loadingTemplate">
        <div class="avatarRow">
            <p-button class="cmn-icon-btn-secondary notification-btn"
                [ngClass]="{'is-more-then-nine': studioNotificationList?.length > 9}" (click)="notificationMenu.toggle($event);"
                [rounded]="true" [text]="true">
                <i class="pi pi-bell text-primary" style="font-size: 24px; cursor: pointer;"></i>
                <p-badge *ngIf="studioNotificationList?.length > 0" class="notification-badge"
                    [value]="studioNotificationList.length > 9 ? '9+' : studioNotificationList.length" severity="danger" />
            </p-button>
            <div>
                <p>{{ studioDetail?.studioName || '' }}</p>
                <span>{{ studioDetail?.contactName || '' }}</span>
            </div>
            <img [src]="studioDetail?.studioLogoUrl ? studioDetail.studioLogoUrl : placeHolderImage"
                alt="Logo image" />
        </div>
    </ng-container>

    <ng-template #loadingTemplate>
        <div class="avatarRow d-flex align-items-center">
            <div>
                <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
                <p-skeleton width="5.5rem" styleClass="mb-2"></p-skeleton>
            </div>
            <p-skeleton width="80px" height="50px" styleClass="ml-2"></p-skeleton>
        </div>
    </ng-template>

</div>
<div class="main-content">
    @if(this.commonFun.isStudioDataSet["_value"]){
    <router-outlet></router-outlet>
    }
</div>

<p-tieredMenu class="notification-tieredMenu" #notificationMenu [model]="studioNotificationMenu" [popup]="true">
    <ng-template let-item pTemplate="item">
        <div (click)="$event.stopPropagation()" class="d-flex flex-col gap-2">
            <div class="heading">
                <h4>
                    Notification
                </h4>
                <p-button (click)="notificationMenu.hide()" class="close-icon" icon="pi pi-times" [rounded]="true" [text]="true" />
            </div>
            <ng-container *ngIf="studioNotificationList?.length > 0; else noNotifications">
                <div class="detail">
                    <p (click)="clearAllNotification()" class="mark-as-read-text">Mark all as read</p>

                    <div class="message-list">
                        <div *ngFor="let notification of studioNotificationList" (click)="redirectToRelativePage(notification)" class="message-box">
                        <!-- <p-button (click)="notificationMenu.hide()" class="cmn-icon-btn-secondary btn-sm close" icon="pi pi-times" [rounded]="true" [text]="true" /> -->

                            <div class="message-heading">
                                <h5 class="date">{{ (notification?.timestamp) ? millisecondsToDate(notification.timestamp) : '-' }}</h5>
                            </div>
                            <h5 class="title">{{notification?.type || '-'}}</h5>

                            <div class="message-detail">
                                <p>
                                    {{notification?.message || '-'}}
                                </p>
                            </div>
                            <div class="text-end">
                                <span (click)="$event.stopPropagation(); clearNotification(notification)" class="mark-as-read-text fs-12">Remove</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #noNotifications>
                <div class="p-3 mt-2 text-center" style="height: 82vh;">
                    No notifications to show.
                </div>
            </ng-template>

        </div>
    </ng-template>
</p-tieredMenu>
<p-confirmDialog class="cus-confirmDialog"></p-confirmDialog>
<p-toast></p-toast>
