import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router, RouterModule } from '@angular/router';
import { getAuth, signOut } from 'firebase/auth';
import { CommonServiceService } from '../shared/services/common-service.service';
import { SharedModule } from '../shared/shared.module';
import { UserService } from '../shared/services/user/user.service';
import { TeammateService } from '../shared/services/teammateService/teammate.service';
import { ZohoService } from '../shared/services/zoho/zoho.service';
import { NotificationType, OrgMgrStatus } from '../shared/constants/enums';
import lodash from 'lodash';
import { LoaderService } from '../shared/services/loader/loader.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription, filter } from 'rxjs';
import { environment } from '../../environments/environment.stage';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import moment from 'moment';
import { BadgeModule } from 'primeng/badge';
import { TieredMenu } from 'primeng/tieredmenu';
import { OrganizationService } from '../shared/services/organization/organization.service';
import { CustomTokenReqType } from '../shared/constants/enums';

@Component({
  selector: 'app-studio',
  standalone: true,
  imports: [
    RouterModule,
    SharedModule,
    BadgeModule
  ],
  templateUrl: './studio.component.html',
  styleUrl: './studio.component.scss'
})
export class StudioComponent implements OnInit, AfterContentChecked {
  @ViewChild('notificationMenu') notificationMenu: TieredMenu;

  page: string = '';
  isShowBack: boolean = false;
  goBackData: any;
  stateData: any;
  studioDetail: any = {};
  isLoading: boolean = false
  currentStatus = "";
  activePage: string;
  currentUrl: string;
  previousUrl: string = '/events';
  routerSubscription: Subscription
  placeHolderImage: string = environment.placeholderImageUrl;
  currentEventData: any;
  currentSchoolData: any;
  currentPage: any;
  notificationSub: Subscription;
  studioNotificationList: any[] = []
  studioNotificationMenu: any[] = ['']
  orgSubscription: Subscription;

  constructor(
    private router: Router,
    public commonFun: CommonServiceService,
    private userService: UserService,
    private teammateService: TeammateService,
    private zohoService: ZohoService,
    private loaderService: LoaderService,
    private cdref: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private db: AngularFireDatabase,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private organizationService: OrganizationService
  ) {
    this.currentUrl = this.router.url;
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });

    this.loaderService.show()
    this.isLoading = true;
    let currentUser = getAuth().currentUser;
    if (currentUser) {
      this.userService.loggedIn(currentUser).then(async () => {
        if (this.notificationSub) this.notificationSub.unsubscribe();
        if (!!this.userService.studioID)
          this.notificationSub = this.db.object(`portal/notifications/${this.userService.studioID}`)
            .snapshotChanges().subscribe({
              next: (snapshot: any) => {
                let notificationObj = snapshot.payload.val() || {};
                let notificationList = this.commonFun.convertObjToArr(notificationObj) || [];
                if(notificationList.length > 0)
                this.showNotificationMessage(lodash.cloneDeep(this.studioNotificationList), notificationList)
                this.studioNotificationList = (notificationList || []).filter((a) => a?.isArchived !== true)
                .sort((a, b) => b.timestamp - a.timestamp)},
              error: (err) => {
                console.log('err: ', err);
              }
            })
            if ((this.organizationService.studioSchoolList.getValue()?.length) == 0) {
              this.orgSubscription = this.organizationService.getOrgFromDbRealtime(this.userService.studioID)
            }
        // --- if current user is studio, set studio data
        if (
          this.userService.role == "studioadmin" &&
          (!this.userService.studio ||
            (this.userService.studio && this.userService.studio.key != this.userService.studioID))
        ) {
          let studioData = await this.commonFun.getStudio(this.userService.studioID);
          await this.userService.setStudioData(studioData);
          await this.init();
        }
        this.studioDetail = this.userService.studio;
        this.studioDetail.contactName = this.userService.studio.firebaseId === this.userService.uid ? this.studioDetail.contactName : this.userService.subUserData?.fname + ' ' + this.userService.subUserData?.lname
        // Add Studio rep
        this.teammateService.addRepresentativeInStudio();
        this.commonFun.isStudioDataSet.next(true)
        this.isLoading = false
        this.loaderService.hide()
        // --- if current userService is district, set district data

      }).catch((err) => {
        console.log('err: ', err);
        this.isLoading = false
        this.loaderService.hide()
      });
    }else{
      this.isLoading = false
      this.loaderService.hide()
    }
  }

  async ngOnInit() {
    // this.commonFun.pageHeader.subscribe({
    //   next: pageTitle => {
    //   this.page = pageTitle;
    // }})
    let defaultUrl = window.location;
    let urlWithoutQueryParams = defaultUrl.href;

    if (urlWithoutQueryParams.toLowerCase().includes('general-data-drops')) {
      this.activePage = 'General Inbox';
    }

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
          const currentUrl = event.url;
          // Remove any query parameters from the URL
          const urlWithoutQueryParams = currentUrl.split('?')[0];
          if (urlWithoutQueryParams.toLowerCase().includes('schedule-configuration')) {
              this.activePage = 'Schedule Configuration';
              // this.isShowBack = false;
          } else if (urlWithoutQueryParams.toLowerCase().includes('bookings')) {
              this.activePage = 'Bookings';
              // this.isShowBack = false;
          } else if (urlWithoutQueryParams.toLowerCase().includes('messaging')) {
            this.activePage = 'messaging';
            // this.isShowBack = false;
          } else if (urlWithoutQueryParams.toLowerCase().includes('subject-selection')) {
            this.activePage = 'Subject Selection';
            // this.isShowBack = false;
          } else if (urlWithoutQueryParams.toLowerCase().includes('general-data-drops')) {
            this.activePage = 'General Inbox';
          }
           else {
            if(urlWithoutQueryParams.toLowerCase().includes('edit-event')){
              this.previousUrl = '/events'
            }
              this.activePage = urlWithoutQueryParams.replace(/^\//, '').replace(/\//g, ' > ');
              // Replace "-" with " " and capitalize first letter
              this.activePage = this.activePage.replace(/-/g, ' ').replace(/\b\w/g, firstLetter => firstLetter.toUpperCase());
          }
      }

      if (!this.activePage && event?.url) {
          this.activePage = event.url.replace(/^\//, '').replace(/\//g, ' > ');
      }
      this.currentPage = this.currentUrl.split('/').pop();
      if (this.router.getCurrentNavigation().extras.state) {
        this.currentEventData = this.router.getCurrentNavigation().extras.state['eventData'];
        this.currentSchoolData = this.router.getCurrentNavigation().extras.state?.['schoolData'] || this.router.getCurrentNavigation().extras.state?.['data']?.schoolData;
      }
    });

    this.router.navigate([(this.router?.url).split('/')[1]]);
    const routeTitle: string = this.router?.config[1]?.children?.find((route: Route): boolean => route.path.includes((this.router.url).split('/')[1]))?.title as string;
    this.commonFun.pageHeader.next(routeTitle);

    // Back Button Subscriber
    this.commonFun.goBackButton$.subscribe({
      next: (backBtnData) => {
        if(backBtnData){
        this.goBackData = backBtnData;
        this.stateData = backBtnData.stateData;
        this.isShowBack = backBtnData.isShow;
      }
      },
      error: (err) => {
        console.log('err: ', err);
      }
    })
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  showNotificationMessage(existingNotification: any[], newNotificationList: any[]) {
    let incomingNotificationList: any[] = newNotificationList.filter((notification) => {
      return !lodash.some(existingNotification, (existingNotification) => existingNotification.key == notification.key)
    })

    if (existingNotification.length > 0 && incomingNotificationList.length > 0) {
      incomingNotificationList.forEach((notification) => {
        if (notification?.isArchived !== true) {
          this.messageService.add({
            severity: 'info',
            life: 5000,
            detail: notification.message,
          });
        }
      })
    }
  }

  logout() {
    signOut(getAuth()).then(() => {
      // Sign-out successful.
      this.router.navigate(['/auth']);
    }).catch((error: any) => {
      console.log('error: ', error);
      // An error happened.
    });
  }

  back() {
    let navigateTo = this.previousUrl;
    let stateData = this.stateData;

    if (navigateTo === '/events' && stateData) {
      stateData = {
        eventData: { type: 'edit', event: { ...(stateData.eventData || {}) } },
      };
    } else if (navigateTo == '/schools/csv-preview') {
      navigateTo = '/schools'
    }

    let queryParams: any = {};
    if(navigateTo.includes('?')){
      const params = new URLSearchParams(lodash.cloneDeep(navigateTo).split('?')[1]);
      for (const [key, value] of params.entries()) {
        queryParams[key] = value;
      }
      navigateTo = navigateTo.split('?')[0];
    }

    if(navigateTo == this.currentUrl){
      navigateTo = '/events';
    }

    const stateToSend = lodash.cloneDeep(stateData || {});
    this.router.navigate([navigateTo], { state: { data: stateToSend }, queryParams });
    this.commonFun.pageHeader.next(this.goBackData.pageTitle);
    this.isShowBack = false;
    this.goBackData = {};
    this.stateData = {};
  }

  onPageChange(){

  }

  async init() {
    this.currentStatus =
      this.userService.studio.status || OrgMgrStatus.APPROVED_AWAITING_CONFIGURATION;

    let preventFurtherProcess = false;
    switch (this.currentStatus) {
      case OrgMgrStatus.APPLIED:
        preventFurtherProcess = true;
        // this.commonFun.presentAlert(
        //   "Your application is not approved yet by High5 admin. Please wait until High5 admin review and approve your application.<br/><br/>You will get email notification when your application will be approved.<br/><br/>Thank you.",
        //   "Alert",
        //   [
        //     {
        //       text: "Ok",
        //     },
        //   ]
        // );
        break;

      case OrgMgrStatus.APPROVED_AWAITING_CONFIGURATION:
        // --- check for my account step
        let isMyAcCompleted = await this.isMyAcCompleted();
        if (!isMyAcCompleted) {
          this.currentStatus = "MyAc";
          preventFurtherProcess = true;
          return;
        }

        // --- check for agreement step
        let isAgreementsCompleted = this.isAgreementsCompleted();
        if (!isAgreementsCompleted) {
          preventFurtherProcess = true;
          this.currentStatus = "Agreement";
          return;
        }

        // --- change status to active
        this.currentStatus = OrgMgrStatus.ACTIVE;
        let [, errorUpdatingStatusToActive] =
          await this.commonFun.executePromise(
            this.teammateService.changeOrgMgrStatus(
              this.userService.studioID,
              this.userService.studio,
              OrgMgrStatus.ACTIVE
            )
          );
        if (errorUpdatingStatusToActive) {
          preventFurtherProcess = true;
          this.commonFun.handleError(errorUpdatingStatusToActive);
          return;
        }
        break;
    }

    if (preventFurtherProcess) return;
  }

  // --- check if my account step completed
  async isMyAcCompleted() {
    let studioData = this.userService.studio;

    // --- check if billing details present
    if (
      !studioData.billingCountry ||
      !studioData.billingState ||
      !studioData.billingCity ||
      !studioData.billingAddress ||
      !studioData.billingPostCode
    ) {
      console.log("SETUP:: MyAcStep:: Billing details missing");
      return false;
    }

    // --- check if credit card present
    let zohoCustomerID = studioData.zohoCustomerID;
    if (!zohoCustomerID) {
      console.log(
        "SETUP:: MyAcStep:: zoho account missing. Add card details to create one."
      );
      return false;
    }

    let [readCardRes, readCardErr] = await this.commonFun.executePromise(
      this.zohoService.readCustomerCards(zohoCustomerID)
    );
    let error = readCardErr;
    if (!error && readCardRes.status != 200) {
      error = readCardRes.message;
    }
    if (error) {
      // this.commonFun.presentAlert(
      //   this.commonFun.prepareErrorMessage(error),
      //   "Error"
      // );
      console.log(
        "SETUP:: MyAcStep:: Error:: Error reading card details",
        error
      );

      return false;
    }
    let cardDetails = this.zohoService.getLatestCard(readCardRes.data);
    if (!cardDetails) {
      console.log("SETUP:: MyAcStep:: Card details not added.");
      return false;
    }

    return true;
  }

  // --- check if agreements step completed
  isAgreementsCompleted() {
    let contracts = lodash.get(this.userService.studio, "settings.Legal.Contracts");
    if (!contracts) {
      console.log("SETUP:: AgreementsStep:: Agreement not signed.");
      return false;
    }

    return true;
  }

  openConfirmationDialog(
    title: string,
    msg: string,
    positiveBtnTxt: string,
    negativeBtnTxt: string,
    dismissOnOutsideClick: boolean = false,
    showAcceptBtn: boolean = true,
    showCloseBtn: boolean = false,
    positiveBtnHandler?: () => void,
    negativeBtnHandler?: () => void
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.confirmationService.confirm({
        message: msg,
        header: title,
        acceptLabel: positiveBtnTxt,
        rejectLabel: negativeBtnTxt,
        accept: () => {
          if (positiveBtnHandler) positiveBtnHandler();
          resolve(true);
        },
        reject: () => {
          if (negativeBtnHandler) negativeBtnHandler();
          resolve(false);
        },
        rejectVisible: showCloseBtn,
        acceptVisible: showAcceptBtn,
        blockScroll: true,
        closeOnEscape: true,
        dismissableMask: dismissOnOutsideClick,
        acceptIcon: 'none',
        rejectIcon: 'none',
        defaultFocus: 'none'
      });
    });
  }

  millisecondsToDate(ms) {
    if (typeof ms !== 'number' || isNaN(ms)) {
        return '-';
    }
    let momentObj = moment(ms);
    if (!momentObj.isValid()) {
        return 'Invalid date';
    }
    let formattedDate = momentObj.format('Do MMMM YYYY');
    let formattedTime = momentObj.format('hh:mm A');
    let formattedDateTime = `${formattedDate}, ${formattedTime}`;
    return formattedDateTime;
  }

  async clearNotification(notification) {
    if (notification.key && this.userService?.studioID) {
      await this.commonFun.archiveNotification(this.userService.studioID, notification.key)
    }
  }

  async clearAllNotification() {
    let notificationArr: string[] = this.studioNotificationList.map((notification) => {
      if (notification?.isArchived !== true) {
        return notification.key
      }
    })
    await this.commonFun.archiveAllNotifications(this.userService.studioID, notificationArr)
  }

  async redirectToRelativePage(notification) {
    switch (notification.type) {
      case NotificationType.GENERAL_DATA_DROPS:
        this.router.navigate(['/general-data-drops']);
        break;
  
      case NotificationType.SCHOOL_UPLOADS:
        if (notification.orgId) {
          try {
            let orgData = await this.organizationService.getOrg(notification.orgId);
            console.log('orgData: ', orgData);
  
            if (orgData) {
              this.router.navigate(['/schools/database'], { 
                relativeTo: this.activatedRoute,
                state: { data: { schoolData: orgData } }
              });
              this.commonFun.pageHeader.next(`${orgData?.orgName} - Database`);
            }
          } catch (error) {
            console.error('Error fetching organization data:', error);
            // Handle error if needed
          }
        } else {
          this.router.navigate(['/schools']);
        }
        break;
  
      default:
        console.warn('Unknown notification type:', notification.type);
        break;
    }
  
    if (this.notificationMenu) {
      this.notificationMenu.hide();
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.notificationSub) {
      this.notificationSub.unsubscribe();
    }

    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe()
    }
  }
  
  async goToAdminPanel(){
    if(!!this.userService?.studioID){
      let payload = {
        orgID: this.userService.studioID,
        type: CustomTokenReqType.SELF,
        payload: await getAuth().currentUser.getIdToken()
      }
      let url = environment.redirectToHigh5;
      url += 'tokenBasedSignIn'
      let queryParams = lodash
                .chain(payload)
                .map((value, key) => {
                  return `${key}=${value}`;
                })
                .join("&")
                .value();

      this.commonFun.goToLink(`${url}?${queryParams}`);
    }
  }
}
