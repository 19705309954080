import { Component, OnInit } from "@angular/core";
import lodash from "lodash";
import { Router } from "@angular/router";
import { SharedModule } from "../../shared/shared.module";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { CommonServiceService } from "../../shared/services/common-service.service";
import { AuthService } from "../../shared/services/auth/auth.service";
import { ApiHelperService, CloudFnNames } from "../../shared/services/apiHelperService/api-helper.service";
import { OrgSearchType } from "../../shared/constants/enums";
import { UserService } from "../../shared/services/user/user.service";
import { ConfirmationService } from "primeng/api";

@Component({
  selector: 'app-find-organization',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './find-organization.component.html',
  styleUrl: './find-organization.component.scss',
})
export class FindOrganizationComponent implements OnInit {
  orgList: any[] = [];
  showOrgList: any[] = [];
  orgName: string;

  languageChooserStyles = {
    language_dropdown_btn: { color: 'white' },
    language_icon: { filter: 'invert(1)' },
  };
  constructor(
    private db: AngularFireDatabase,
    private router: Router,
    private commonService: CommonServiceService,
    private authService: AuthService,
    private apiHelperService: ApiHelperService,
    private commonFun: CommonServiceService,
    public userService: UserService,
    private confirmationService: ConfirmationService
  ) {}

  // --- triggers when org search input changes
  isSearching = false;
  onOrgSrchInputChange = lodash.debounce(async () => {
    this.isSearching = true;
    let searchTxt: string = lodash.trim(this.orgName).toLowerCase();

    if (searchTxt.length < 4) {
      this.showOrgList = [];
      this.isSearching = false;
      return;
    }

    // --- get matching orgs from given input
    let [matchingOrgs, errInSearchOrgs] =
      await this.commonService.executePromise(
        this.apiHelperService.postToCloudFn(CloudFnNames.searchOrgs, {
          type: OrgSearchType.NAME,
          userInput: searchTxt,
          restrictions: {
            studioID: this.userService.studioID,
          },
        })
      );

    if (errInSearchOrgs) {
      this.isSearching = false;
      this.showOrgList = [];
      console.log('errInSearchOrgs', errInSearchOrgs);
      return;
    }

    matchingOrgs = lodash.get(matchingOrgs, 'result.data');
    this.showOrgList = lodash.orderBy(matchingOrgs, (orgData) =>
      lodash.toLower(orgData.orgName)
    );
    this.isSearching = false;
  }, 200);

  // --- show alert
  presentAlert(msg: string) {
    this.openConfirmationDialog('Error', msg, null, null, false, false, false);
  }

  async selectOrg(org) {
    this.orgName = '';
    this.showOrgList = [];
      this.userService.orgID = org.key;
      this.userService.studioID = org.studioID;
      this.userService.org = org;
      this.router.navigate(['/generic/slot-booking']);
  }

  async ngOnInit(): Promise<void> {
    // --- wait for authorization
    let authorized = await this.commonFun.waitForAuthorization(
      this.authService
    );
    if (!authorized) return;
  }

  openConfirmationDialog(
    title: string,
    msg: string,
    positiveBtnTxt: string,
    negativeBtnTxt: string,
    dismissOnOutsideClick: boolean = false,
    showAcceptBtn: boolean = true,
    showCloseBtn: boolean = false,
    positiveBtnHandler?: () => void,
    negativeBtnHandler?: () => void
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.confirmationService.confirm({
        message: msg,
        header: title,
        acceptLabel: positiveBtnTxt,
        rejectLabel: negativeBtnTxt,
        accept: () => {
          if (positiveBtnHandler) positiveBtnHandler();
          resolve(true);
        },
        reject: () => {
          if (negativeBtnHandler) negativeBtnHandler();
          resolve(false);
        },
        rejectVisible: showCloseBtn,
        acceptVisible: showAcceptBtn,
        blockScroll: true,
        closeOnEscape: true,
        dismissableMask: dismissOnOutsideClick,
        acceptIcon: 'none',
        rejectIcon: 'none',
        defaultFocus: 'none'
      });
    });
  }
}
