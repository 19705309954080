<div class="container d-flex justify-content-center align-items-center" style="min-height: 100vh">
    <div class="card text-center booking-info" *ngIf="!cancelSitting && !pickNewTime && !confirmSitting && eventMetaData && !isShowLoader">
        <div class="d-flex gap-3 align-items-center">
            <div class="heading">
                <span>Your school:</span>
            </div>
            <div class="detail">
                <span *ngIf="!isLoading">{{ organization || "-" }}</span>
                <p-skeleton *ngIf="isLoading" width="100%" height="20px" styleClass="mb-2" />
            </div>
        </div>
        <div class="d-flex gap-3 align-items-center">
            <div class="heading">
                <span>Your Event:</span>
            </div>
            <div class="detail">
                <span *ngIf="!isLoading">{{ eventMetaData?.displayName || "-" }}</span>
                <p-skeleton *ngIf="isLoading" width="100%" height="20px" styleClass="mb-2" />
            </div>
        </div>
        <div class="d-flex gap-3 align-items-center">
            <div class="heading">
                <span>You:</span>
            </div>
            <div class="detail">
                <span *ngIf="!isLoading">{{ indName || "-" }} {{ (grade ? "," + " grade " + grade : "") || ""
                    }}{{ (studentID ? ", " + studentID : "") || "" }}</span>
                     <p-skeleton *ngIf="isLoading" width="100%" height="20px" styleClass="mb-2" />
            </div>
        </div>
        <div class="d-flex gap-3 align-items-center">
            @if (isInvite) {
                <div class="heading">
                    <span>Your current sitting:</span>
                </div>
                <div class="detail">
                    <span *ngIf="!isLoading && currentSlotData?.status_appointment != slotStatus.DECLINED">{{ (currentSlotData?.startTime | date : "hh:mm a 'on' MMM dd, yyyy") || 'Not Scheduled' }}
                    </span>
    
                    <span *ngIf="!isLoading && currentSlotData?.status_appointment === slotStatus.DECLINED">
                        Cancelled
                    </span>
                    <p-skeleton *ngIf="isLoading" width="100%" height="20px" styleClass="mb-2" />
                </div>
            } @else {
                <p class="card-footer mb-0 fs-16 mt-3 text-danger">
                    You are not currently on the invite list for this event. Please check with event organizers if you feel that this is in error.
                </p>
            }
        </div>

        <div *ngIf="currentSlotData?.status_appointment == slotStatus.CONFIRMED" class="d-flex gap-3 align-items-center">
            <div class="heading">
                <span>Status:</span>
            </div>
            <div class="detail">
                <span *ngIf="!isLoading">{{ (currentSlotData?.status_appointment? (currentSlotData?.status_appointment | titlecase) : '') || '-' }}
                </span>
                <p-skeleton *ngIf="isLoading" width="100%" height="20px" styleClass="mb-2" />
            </div>
        </div>

        <div class="card-footer mt-3" *ngIf="isInvite">
            <p-button *ngIf="!isGeneric && currentSlotData?.status_appointment != slotStatus.CONFIRMED && currentSlotData?.status_appointment != slotStatus.DECLINED && currentSlotData" label="Confirm this time" class="rounded-full cmn-btn-secondary"
               [disabled]="isLoading" (onClick)="confirmSlot()"></p-button>
            <p-button label="Pick new time" class="rounded-full cmn-btn-secondary"
               [disabled]="isLoading" (onClick)="pickNewTime = true; getPickNewTime()"></p-button>
            <p-button *ngIf="(currentSlotData?.status_appointment != slotStatus.DECLINED) && !isGeneric" label="Cancel my sitting" class="rounded-full cmn-btn-secondary"
               [disabled]="isLoading" (onClick)="cancelSitting = true"></p-button>
        </div>
    </div>

    <div class="card text-center" *ngIf="!eventMetaData && !isShowLoader">
        <span>The event you are looking for has been cancelled.</span>
    </div>

    <div class="card" *ngIf="cancelSitting && !confirmCancellation">
        @if ((currentSlotData?.status_appointment != slotStatus.DECLINED)) {
            <div>
                <div class="text-center" style="display: grid">
                    <span>You are about to cancel</span>
                    <span>your sitting for <b>{{ eventMetaData?.displayName || "-" }}</b>.</span>
                </div>
                <div class="text-center mt-3">Are you sure?</div>
                <div *ngIf="previousSlotData && currentSlotData" class="fs-12 text-blue text-center" style="max-width: 370px; margin: 16px auto 0 auto;">Note: Your current sitting has been changed from "<b>{{previousSlotData?.slotStartTimeString || ''}}</b>" to "<b>{{currentSlotData?.slotStartTimeString || ''}}</b>".</div>
            </div>
            <div class="card-footer mt-3">
                <p-button label="Take me back" class="rounded-full cmn-btn-secondary"
                    (onClick)="cancelSitting = false"></p-button>
                <p-button (onClick)="confirmCancellationStatus()" label="Yes cancel my sitting"
                    class="rounded-full cmn-btn-secondary"></p-button>
            </div>
        } @else {
            <div style="max-width: 400px; text-align: center;">
                <div class="text-center d-flex flex-col gap-2" style="display: grid">
                    <span>You have already cancelled</span>
                    <span>your sitting for <b>{{ eventMetaData?.displayName || "-" }}</b>.</span>
                </div>
            </div>
        }
    </div>

    <div *ngIf="confirmCancellation && !confirmSitting" class="confirmCancellation">
        <span class="text-center">
            We’re sorry to see you go.
            Your cancellation is confirmed.
        </span>
    </div>

    <div class="card text-center booking-info date-time-sec" *ngIf="pickNewTime">
        <div class="d-flex flex-sm-col gap-3 align-items-center">
          <div class="heading">
            <span>Your preferred date:</span>
          </div>
          <div class="detail w-full">
            <p-dropdown class="w-full cmn-dropdown" [options]="slotPreferredDate" [(ngModel)]="selectedPreferredDate" optionLabel="date"
              placeholder="Select preferred date" (onChange)="preferredValueChange()"></p-dropdown>
            <div class="invalid-feedback" *ngIf="!selectedPreferredDate && isShowErrorMassage">Preferred date is required</div>
          </div>
        </div>
        <div class="d-flex flex-sm-col gap-3 align-items-center">
          <div class="heading">
            <span>Your preferred time:</span>
          </div>
          <div class="detail w-full">
            <p-dropdown class="w-full cmn-dropdown" [(ngModel)]="selectedTimeSlot" [options]="slotPreferredTime" optionLabel="date"
              placeholder="Select preferred time"></p-dropdown>
            <div class="invalid-feedback" *ngIf="!selectedTimeSlot && isShowErrorMassage">Preferred time is required</div>
          </div>
        </div>
        <div class="d-flex gap-3 align-items-center  justify-content-center pt-3 mx-auto">
            <p-button label="Cancel" class="rounded-full cmn-btn-secondary w-full"
                (onClick)="onCancelSlotBooking()"></p-button>
            <p-button (onClick)="pickNewSlotTime()" label="Confirm" class="rounded-full cmn-btn-secondary w-full"></p-button>
        </div>
      </div>
    <div *ngIf="confirmSitting" class="confirmSitting-card">
        <span>Your sitting is confirmed for {{ currentSlotData?.startTime | date : "MMM dd, yyyy 'at' hh:mm a" }}
        </span>
    
        <span>
            Please show up at least 10 minutes before your session starts.
        </span>
        <span> Thank you! </span>
    </div>
</div>
<p-toast></p-toast>
<p-confirmDialog class="cus-confirmDialog"></p-confirmDialog>