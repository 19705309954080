import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import lodash from 'lodash';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SharedModule } from '../../shared/shared.module';
import { UserService } from '../../shared/services/user/user.service';
import { IndSearchType } from '../../shared/interface/types';
import { CommonServiceService } from '../../shared/services/common-service.service';
import { EventService } from '../../shared/services/event/event.service';
import { IndividualApiService } from '../../shared/services/individuals/individual.service';
import { LoaderService } from '../../shared/services/loader/loader.service';
import { AuthService } from '../../shared/services/auth/auth.service';

@Component({
  selector: 'app-pick-individual',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './pick-individual.component.html',
  styleUrl: './pick-individual.component.scss',
})
export class PickIndividualComponent {
  isLoading: boolean = false;
  orgId: any;
  indId: any;
  studioId: any;
  organization: any;
  eventMetaData: any;
  genericPageForm: FormGroup;
  selectedIndKey: string;
  step: number;
  selectedEventId: string;
  eventList: any[] = [];
  isShowEventDropDown: boolean = true;

  constructor(
    public activatedRoute: ActivatedRoute,
    public userService: UserService,
    public eventService: EventService,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private commonService: CommonServiceService,
    private individualApiService: IndividualApiService,
    private router: Router,
    private messageService: MessageService,
    private authService: AuthService,
    private confirmationService: ConfirmationService
  ) {
    this.genericPageForm = this.fb.group({
      studentId: [null, [Validators.required]],
      name: '',
    });
  }

  async ngOnInit() {
    this.loaderService.show()
    let authorized = await this.commonService.waitForAuthorization(this.authService);
    this.isLoading = true;
    if (!authorized) {
      this.loaderService.hide();
      this.isLoading = false;
      return;
    }
    this.activatedRoute.queryParams.subscribe(async (data: any) => {
      if ('orgId' in data) {
        this.orgId = data.orgId;
        this.userService.orgID = this.orgId;
      } else if (this.userService?.orgID) {
        this.orgId = this.userService.orgID || '';
      }
      if ('indId' in data) {
        this.indId = data.indId;
      }

      if ('eventId' in data) {
        this.isShowEventDropDown = !data.eventId;
        this.selectedEventId = data.eventId;
      } else {
        this.isShowEventDropDown = true;
      }

      if ('studioId' in data) {
        this.studioId = data.studioId;
        this.userService.studioID = this.studioId;
      } else if (this.userService.studioID) {
        this.studioId = this.userService.studioID;
      }

      if ('orgName' in data) {
        this.organization = data.orgName?.replace(/%/g, ' ') || '';
      } else if (!!this.userService?.org?.orgName) {
        this.organization = this.userService.org.orgName;
      }

      if(!this.orgId || !this.studioId){
        this.router.navigate(['/generic'])
      }
    });

      let [orgEventsRes, orgEventsErr] = await this.commonService.executePromise(this.eventService.getOrgAllEvents(this.userService.orgID, this.userService?.studioID));
      if (orgEventsErr) {
        this.presentAlert(this.commonService.prepareErrorMessage(orgEventsErr));
        this.loaderService.hide();
        return;
      }
    if(!!orgEventsRes) this.eventList = this.commonService.convertObjToArr(orgEventsRes) || [];
    try {
      await this.getMetaDataFromEvent();
      this.loaderService.hide()
      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.loaderService.hide()
      this.isLoading = false;
    }
  }

  async getMetaDataFromEvent() {
    this.eventMetaData = await this.eventService.getMetaDataFromEvent(
      this.studioId,
      this.orgId,
      this.selectedEventId
    );
    this.eventMetaData = this.eventMetaData.val();
    console.log('this.eventMetaData: ', this.eventMetaData);
  }

  async verifyStudentNumber() {
    let errMsg;
    let userInput;
    this.genericPageForm.markAllAsTouched();
    if (!this.genericPageForm.valid) {
      errMsg = 'Please enter student ID.';
    }

    // --- validation error handling
    if (errMsg) {
      this.messageService.add({
        severity: 'info',
        life: 5000,
        detail: errMsg,
      });
      return;
    }

    this.loaderService.show();
    userInput = this.genericPageForm.get('studentId').value;

    // --- get matching indIDs from given student ID
    let [matchingIndIDs, err] = await this.commonService.executePromise(
      this.individualApiService.searchInds(
        this.orgId,
        IndSearchType.STUDENT_ID,
        lodash.trim(userInput),
        ['key', 'label'],
      )
    );

    if (err) {
      this.messageService.add({
        severity: 'error',
        life: 5000,
        summary: 'error',
        detail: err,
      });
      this.loaderService.hide();
      return;
    }

    if (matchingIndIDs?.length > 1) {
      this.messageService.add({
        severity: 'error',
        life: 6000,
        summary: 'error',
        detail: `More than one individual was found with the organization ID <b>${userInput}</b>. Please contact your organization to resolve this issue - organization ID(StudentID/Number) must be unique for each individual.`,
      });
      this.loaderService.hide();
      return;
    }

    let matchingIndID = lodash.get(matchingIndIDs, '[0].key');
    let matchingIndName = lodash.get(matchingIndIDs, '[0].label');

    if (!matchingIndID) {
      this.messageService.add({
        severity: 'error',
        life: 5000,
        summary: 'error',
        detail: 'Sorry, individual not found. Please try again',
      });
      this.loaderService.hide();
      return;
    }
    this.loaderService.hide();
    this.router.navigate(['generic/slot-scheduling'], {
      queryParams: {
        orgId: this.orgId,
        indId: matchingIndID,
        eventId: this.selectedEventId,
        studioId: this.studioId,
        orgName: this.organization,
        indName: matchingIndName || '',
        grade: null,
        studentID: userInput,
        isGeneric: true
      },
    });
  }

  // --- show alert
  presentAlert(msg: string) {
    this.openConfirmationDialog('Error', msg, null, null, false, false, false);
  }

  openConfirmationDialog(
    title: string,
    msg: string,
    positiveBtnTxt: string,
    negativeBtnTxt: string,
    dismissOnOutsideClick: boolean = false,
    showAcceptBtn: boolean = true,
    showCloseBtn: boolean = false,
    positiveBtnHandler?: () => void,
    negativeBtnHandler?: () => void
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.confirmationService.confirm({
        message: msg,
        header: title,
        acceptLabel: positiveBtnTxt,
        rejectLabel: negativeBtnTxt,
        accept: () => {
          if (positiveBtnHandler) positiveBtnHandler();
          resolve(true);
        },
        reject: () => {
          if (negativeBtnHandler) negativeBtnHandler();
          resolve(false);
        },
        rejectVisible: showCloseBtn,
        acceptVisible: showAcceptBtn,
        blockScroll: true,
        closeOnEscape: true,
        dismissableMask: dismissOnOutsideClick,
        acceptIcon: 'none',
        rejectIcon: 'none',
        defaultFocus: 'none'
      });
    });
  }
}
