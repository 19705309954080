import { Component } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ScheduleConfigurationCalendarComponent } from '../../shared/components/schedule-configuration-calendar/schedule-configuration-calendar.component';
import { LoaderService } from '../../shared/services/loader/loader.service';
import { CommonServiceService } from '../../shared/services/common-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../shared/services/event/event.service';
import { Subscription } from 'rxjs';
import {
  ApiHelperService,
  CloudFnNames,
} from '../../shared/services/apiHelperService/api-helper.service';
import { AuthService } from '../../shared/services/auth/auth.service';
import { EventBookingInfoComponent } from '../../shared/components/event-booking-info/event-booking-info.component';

@Component({
  selector: 'app-slot-booking-list',
  standalone: true,
  imports: [
    SharedModule,
    ScheduleConfigurationCalendarComponent,
    EventBookingInfoComponent,
  ],
  templateUrl: './slot-booking-list.component.html',
  styleUrl: './slot-booking-list.component.scss',
})
export class SlotBookingListComponent {
  allInds: any[] = [];
  orgId: any;
  eventData: any;
  eventId: any;
  studioId: any;
  eventSubscriber: Subscription;

  constructor(
    private apiHelperService: ApiHelperService,
    private commonFun: CommonServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private loaderService: LoaderService,
    private eventService: EventService
  ) {
    this.route.queryParams.subscribe((params) => {
      if ('eventId' in params) {
        this.eventId = params['eventId'];
      }

      if ('orgId' in params) {
        this.orgId = params['orgId'];
      }
      if ('studioId' in params) {
        this.studioId = params['studioId'];
      }
    });
    this.eventData = {
      event: {
        orgId: this.orgId,
        eventId: this.eventId,
        studioId: this.studioId,
      },
      type: 'edit',
    };
  }

  async ngOnInit() {
    let authorized = await this.commonFun.waitForAuthorization(
      this.authService
    );
    if (!authorized) {
      this.loaderService.hide();
      return;
    }
    await this.getIndFullName();
  }

  async getIndFullName(): Promise<void> {
    this.loaderService.show();
    try {
      if (!this.studioId || !this.orgId || !this.eventId) {
        throw new Error('Params missing!');
      }
      let [getSubjectFullNameRes, getSubjectFullNameErr]: any =
        await this.commonFun.executePromise(
          this.apiHelperService.postToCloudFn(CloudFnNames.getIndFullName, {
            orgID: this.orgId,
            studioID: this.studioId,
            eventID: this.eventId,
          })
        );

      if (getSubjectFullNameErr) {
        console.error(getSubjectFullNameErr);
        throw new Error('Failed to fetch individual full names.');
      }

      if (getSubjectFullNameRes?.result?.data) {
        let allIndDataObj = getSubjectFullNameRes.result.data;
        if (allIndDataObj) {
          this.allInds = Object.keys(allIndDataObj).map((key) => {
            let fullName = allIndDataObj[key];
            let splitName = fullName.split(' ');

            return {
              DBkey: key,
              firstName: splitName[0],
              lastName: splitName.slice(1).join(' '),
            };
          });
        }
      }
      this.loaderService.hide();
    } catch (error) {
      console.error('Error in getIndFullName:', error);
      this.loaderService.hide();
      throw error;
    }
  }
}
