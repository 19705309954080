<div class="drop-data-container">

  <div class="main-div">
    <div class="upload-section">
      <div class="pt-65">
        <!-- <div class="logo-option text-center mx-auto mt-4 max-w-150px">
          <img src="assets/png/school-logo.png" />
        </div> -->
        <div class="text-center text-primary upload-section-heading">
          <h4>
            Upload Data to
          </h4>
          <h3>
            {{studioName || '-'}}
          </h3>
        </div>

        <div class="general-dropbox-section">
          <h4 class="fs-20 text-center m-0">General Inbox</h4>
          <div class="h-full flex-grow">
            <form class="container h-full" [formGroup]="dropboxForm">
              <div class="d-flex gap-4 mt-4 align-items-center flex-col pt-20px">
                <div class="d-flex gap-20px flex-sm-col justify-content-between h-full w-full">
                  <div class="w-full">
                    <span class="p-float-label cus-float-label">
                      <input (change)="storeValInLocalStorage()" #eventNameInput [required]="true" class="w-full" pInputText id="orgName"
                        formControlName="orgName" />
                      <label for="orgName">Your organization’s name</label>
                    </span>
                    <div *ngIf="(dropboxFormControls['orgName'].touched)" class="text-start invalid-feedback m-b-20">
                      <small *ngIf="(dropboxFormControls['orgName'].errors?.['required'])">
                        Event Name is required!
                      </small>
                    </div>
                  </div>
                  <div class="w-full">
                    <span class="p-float-label cus-float-label">
                      <input (change)="storeValInLocalStorage()" #eventNameInput [required]="true" class="w-full" pInputText id="email"
                        formControlName="email" type="email" />
                      <label for="email">Your email</label>
                    </span>
                    <div *ngIf="(dropboxFormControls['email'].touched)" class="text-start invalid-feedback m-b-20">
                      <small *ngIf="(dropboxFormControls['email'].errors?.['required'])">
                        Organization Email is required!
                      </small>
                      <small
                        *ngIf="(dropboxFormControls['email'].errors?.['email']) && !(dropboxFormControls['email'].errors?.['required'])">
                        Please enter valid email!
                      </small>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-full">
                  <div class="position-relative w-full h-full">
                    <div (drop)="onDrop($event)" (dragover)="onDragOver($event)" (click)="imageInput.click()"
                      class="image-doc-uploader">
                      <div>
                        <input multiple style="display: none;" class="image-upload-input" #imageInput type="file"
                          (change)="onFileSelected($event)">

                        <div class="drop-container">
                          <img src="../../../assets/svg/image-upload-icon.svg" alt="">
                          <label for="fileInput" *ngIf="files.length == 0" class="drop-label">Drop File Here</label>
                        </div>

                        <div class="drop-label" *ngIf="files?.length > 0">
                          {{ files.length > 1 ? files.length : '' }} {{ files.length == 1 ? files[0].name : " files selected" }}
                        </div>
                      </div>
                    </div>
                    <div *ngIf="((files?.length == 0) && isTouched)" class="invalid-feedback m-b-20">
                      <small>
                        Minimum one file is required!
                      </small>
                    </div>
                    <div class="fs-12 text-start pt-1 text-primary">
                      Maximum 10 files allowed at a time.
                    </div>
                  </div>
                </div>

              <div class="w-full pt-2 upload-btn">
                <p-button class="cmn-btn-primary w-full " (onClick)="onSubmit()" label="Upload"></p-button>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-toast></p-toast>
  <p-confirmDialog class="cus-confirmDialog"></p-confirmDialog>